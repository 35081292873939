<template>
  <v-app-bar
    id="app-bar"
    absolute
    app
    color="transparent"
    flat
    height="70"
  >
    <v-btn
      class="mr-3 mt-5"
      elevation="1"
      fab
      small
      @click="setDrawer(!drawer)"
    >
      <v-icon v-if="value">
        mdi-view-quilt
      </v-icon>

      <v-icon v-else>
        mdi-dots-vertical
      </v-icon>
    </v-btn>

    <v-spacer />
    <div class="mx-3" />
    <v-menu
      transition="slide-x-transition"
      offset-y
      bottom
    >
      <template v-slot:activator="{ on, attrs }">
        <div class="my-2">
          <v-btn
            color="warning"
            dark
            small
            fab
            v-bind="attrs"
            v-on="on"
          >
            <v-icon>mdi-account-circle</v-icon>
          </v-btn>
        </div>
      </template>

      <v-list>
        <v-list-item-group
          color="primary"
        >
          <v-list-item @click="logout()">
            <v-list-item-icon>
              <v-icon>mdi-account-circle</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>
                <span @click="logout()">Sair</span>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-menu>
    <!-- <v-btn
      class="ml-2 mt-5"
      min-width="0"
      text
      to="/pages/user"
    >
      <v-icon>mdi-account</v-icon>
    </v-btn> -->
  </v-app-bar>
</template>

<script>
  // Components

  // Utilities
  import firebase from 'firebase'
  import { mapState, mapMutations } from 'vuex'
  export default {
    name: 'DashboardCoreAppBar',

    props: {
      value: {
        type: Boolean,
        default: false,
      },
    },

    data: () => ({
      notifications: [
        'Mike John Responded to your email',
        'You have 5 new tasks',
        'You\'re now friends with Andrew',
        'Another Notification',
        'Another one',
      ],
    }),

    computed: {
      ...mapState(['drawer']),
    },

    methods: {
      ...mapMutations({
        setDrawer: 'SET_DRAWER',
      }),
      async logout () {
        this.$store.commit('setSplashScreen', true)
        firebase.auth().signOut().then(() => {
          this.$store.commit('setSplashScreen', false)
          this.$store.commit('SET_LOGOUT')
          this.$router.replace({ name: 'login' })
        })
      },
    },
  }
</script>
